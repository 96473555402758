(function(i, p, w, d, b, $, t){
    var TNT_Curl_Ad = {
        'el': {
            'ad_wrapper': '#tn-curl-wrapper',
            'ad_corner': '.tn-curl-corner',
            'ad_contents': '.tn-curl-contents',
            'ad_small_img': '.tn-curl-small-img',
            'ad_large_img': '.tn-curl-large-img'
        },
        'is_desktop': function() {
            return ($(w).width() > 767) ? true : false;
        },
        'ad_html': function() {
            var html = '' +
            '<style>' +
                this.el.ad_wrapper + ' { position: absolute; top: 0; right: 0; width: 75px; height: 75px; border: 0px none transparent; animation: curl-bounce 5s infinite; z-index: 1003; }' +
                this.el.ad_wrapper + ':hover { border: 0px none transparent; width: 500px; height: 500px; animation: curl-open 0.5s; }' +
                this.el.ad_wrapper + ':hover ' + this.el.ad_large_img + ' { opacity: 1; transition: opacity 1s; }' +
                this.el.ad_corner + ' { position: absolute; background: linear-gradient(45deg, #ddd 17%, #dfdfdf 18%, #f5f5f5 30%, #f8f8f8 34%, #eee 39%, rgba(200,200,200,0) 41%); width: 100%; height: 100%; }'+
                this.el.ad_corner + ':after { position: absolute; right: 0%; top: 100%; display: block; height: 50%; width: 50%; content: ""; border-top-right-radius: 60%; background: -webkit-radial-gradient(-180% 200%, circle, rgba(255,255,255,0) 85%, rgba(0,0,0,.1) 93%); z-index: 1003; }' +
                this.el.ad_corner + ':before { position: absolute; right: 100%; top: 0%; display: block; height: 50%; width: 50%; content: ""; border-top-right-radius: 60%; background: -webkit-radial-gradient(-180% 200%, circle, rgba(255,255,255,0) 85%, rgba(0,0,0,.1) 93%); z-index: 1003; }' +
                this.el.ad_contents + ' { position: absolute; right: 0; top: 0; display: block; height: 125%; width: 125%; overflow: hidden; -webkit-mask: linear-gradient(45deg, transparent 49%, #000 53%); z-index: 1002; }' +
                this.el.ad_contents + ':after { position: absolute; right: 0; top: 0; display: block; content: ""; height: 133%; width: 133%; background: linear-gradient(45deg, rgba(255, 255, 255, 0) 37%, #DDD 62%, rgba(230, 230, 230, 0.1) 64%, rgba(255, 255, 255, 0) 67%), -webkit-radial-gradient(-50% 150%, circle, transparent 74%, rgba(0, 0, 0, 0.2) 74%, transparent 81%); z-index: 1003; }' +
                this.el.ad_contents + ':before { position: absolute; right: 0; top: 0; display: block; content: ""; height: 100%; width: 100%; background-color: #eeeef4; z-index: 1002; }' +
                this.el.ad_small_img + ' { position: absolute; top: 0; right: 0; width: 75px; height: 75px; background: url(' + this.settings.small_image + ') no-repeat; background-position: right top; z-index: 1002; } ' +
                this.el.ad_large_img + ' { position: absolute; background: linear-gradient(225deg, rgba(255, 255, 255, 0), rgba(243, 243, 243, 0.3) 45%, rgba(221, 221, 221, 0.3) 50%, rgb(170, 170, 170) 50%, rgb(187, 187, 187) 56%, rgb(204, 204, 204) 62%, rgb(243, 243, 243) 80%, rgb(255, 255, 255) 100%) repeat scroll 0% 0%, transparent url(' + this.settings.large_image + ') no-repeat scroll 0% 0%; background-position: right top; width: 100%; height: 100%; opacity: 0; border: 0px none transparent; z-index: 1002; }' +
                '@keyframes curl-bounce { 0% {width: 75px; height: 75px;} 25% {width: 75px; height: 75px;} 50% {width: 100px; height: 100px;} 100% {width: 75px; height: 75px;} }' +
                '@keyframes curl-open { 0% {width: 75px; height: 75px;} 100% {width: 500px; height: 500px;} }' +

            '</style>' +
            '<div id="' + this.el.ad_wrapper.replace('#','') + '">' +
                '<a href="' + this.settings.click_tag + '" target="_blank" rel="noopener">' +
                    '<div class="' + this.el.ad_corner.replace('.','') + '">' +
                        '<div class="' + this.el.ad_contents.replace('.','') + '">' +
                            '<div class="' + this.el.ad_small_img.replace('.','') + '"></div>' +
                            '<div class="' + this.el.ad_large_img.replace('.','') + '"></div>' +
                        '</div>' +
                    '</div>' +
                '</a>';
                if (this.settings.tracking_pixel) {
                    html += '<div style="display:none;"><img src="' + this.setting.tracking_pixel + '" height="1" width="1" /></div>';
                }
            html += '</div>';
            return html;
        },
        'load': function(settings) {
            this.settings = settings;

            $(d).find(p).hide();
            $(d).find(p).parent().hide();
            if (this.is_desktop) $(b).append(this.ad_html());
        },
    };

    try {
        if (!i) throw 'Friendly iframe required.';

        $(t.cmds).each(function() {
            TNT_Curl_Ad.load(this.call());
            return false;
        });
    } catch(e) {
        if (w.console) w.console.warn(e);
    }
})(
    (window.inDapIF ? true : false),
    (window.inDapIF ? window.frameElement : null),
    (window.inDapIF ? window.parent : window),
    (window.inDapIF ? window.parent.document : document),
    (window.inDapIF ? window.parent.document.body : document.body),
    (window.inDapIF ? window.parent.jQuery : jQuery),
    (window.__TNT_AD || {})
);